
.skeleton-container {
    min-height: 109px;
}
.pdp-sticky-cta-pricing {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: .5rem;
    gap: 8px;

    .main-pricing {
        font-family: "SF Pro Text";
        font-size: 1.75rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.875rem;
        letter-spacing: .03rem;
    }

    .label-or {
        font-family: "SF Pro Text";
        font-size: .75rem;
        font-style: normal;
        font-weight: 400;
        line-height: .875rem;
        color: #6e6e73;
    }

    .btn-link {
        padding 0 !important
        color: $color-bnn;
        cursor pointer

        &:hover:before {
            background: transparent !important
        }

        .v-icon {
            color: $color-bnn
        }
    }
}
